import {Link} from 'react-router-dom';
import {useAuth} from 'src/auth/core/Auth';

const DashboardUncompleted = () => {
  const {currentUser} = useAuth();
  const status = {
    mail_verified: currentUser?.status?.mail_verified || false, 
    phone_verified: currentUser?.status?.phone_verified || false,
    organisation_completed: currentUser?.status?.organisation_completed || false,
    accepted_last_conditions: currentUser?.status?.accepted_last_conditions || false,
    has_invitations: currentUser?.status?.has_invitations || 0
  }

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xxl-4'>
          <h1>Onderstaande punten staan nog open:</h1>

          <div className='row'>
          { status.accepted_last_conditions ?
              <h2 className='text-info'>1: De laatste voorwaarden zijn geaccepteerd</h2>
            :
              <h2>
                <Link to='/acceptterms' className=''>
                  1: De laatste voorwaarden accepteren
                </Link>
              </h2>
            }
          </div>
        </div>

        <div className='row'>
            { status.mail_verified ?
              <h2 className='text-info'>2: Je mailadres is bevestigd</h2>
            :
              <h2>
                <Link to='/verifyemail' className=''>
                  2: Je mailadres bevestigen
                </Link>
              </h2>
            }
          </div>

          <div className='row'>
            { status.phone_verified ?
              <h2 className='text-info'>3: Je telefoonnummer is bevestigd</h2>
            :
              <h2>
                <Link to='/verifyphone' className=''> 
                  3: Je telefoonnummer bevestigen
                </Link>
              </h2>
            }
          </div>

          <div className='row'>
            { status.organisation_completed ?
              <h2 className='text-info'>4: Je organisatie gegevens zijn kompleet</h2>
            :
              <h2>
                <Link to='/app/organisation-management/organisations' className=''> 
                  4: Je organisatie gegevens invullen
                </Link>
              </h2>
            }
          </div>

          <div className='row'>
            { status.has_invitations === 0 ?
              <h2 className='text-info'>5: Er zijn geen openstaande uitnodigingen als webmaster</h2>
            :
              <h2>
                <Link to='/invitations' className=''> 
                  { "5: Je hebt " + status.has_invitations + " uitnodiging(en) als webmaster" }
                </Link>
              </h2>
            }
          </div>

      </div>
    </>
  )
}

export {DashboardUncompleted}


