/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {isNotEmpty, getValidation, formValidation, delayedClose, formSubmit } from 'src/_config/helpers'
import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import { RegistrationModel } from '../core/_models';
import {InputText} from 'src/components/form/inputText';
import {InputPassword} from 'src/components/form/inputPassword';
import {InputSwitch} from 'src/components/form/inputSwitch';
import Alert from 'src/components/Alert';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  acceptTerms: false,
  phone: ''
}

type Props = {
  registration: RegistrationModel
}


export function Registration() {
  const topRef = useRef<HTMLInputElement | null>( null );

  // de opgehaalde data in de state plaatsen
  const [values, setValues] = useState<RegistrationModel>({
    ...initialValues
  })


  // validaties aanmaken
  const validations = {
    firstname: {label: 'Voornaam', value: 'min:3|max:64|required' },
    lastname: {label: 'Achternaam', value: 'min:3|max:64|required' },
    email: {label: 'Emailadres', value: 'email|required' },
    password: {label: 'Wachtwoord', value: 'password|required|safe:100' },
    acceptTerms: {label: 'Voorwaarden', value: 'switch|required' },
    phone: {label: 'Mobiel nummer', value: 'phone|required' }
  };

  // lokale status
  const [status, setStatus] = useState( { value: 0, message: '' } );

  // scherm sluiten
  const cancel = (withRefresh?: boolean) => {
    window.location.href="/";
  }

  // hulpfunctie voor het vertraagd sluiten van het scherm
  const delay = 5000;
  delayedClose( { status, setStatus, cancel, delay } );  

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth();

  const submit = async (event) => {
    //waardes overnemen, zodat je ze eventueel nog kunt bewerken
    const val = {...values};
    //onnodige informatie verwijderen, zoals opties voor selectboxen

    const resp = await formSubmit( { event, topRef, values:val, validations, setStatus, update: register, create: register, parent: false } );
    if (!resp.data.success){
      setStatus( { value: 2, message: resp.data.errorMessages[0] } );
    }
    else{

    }
  };


  /*
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          values.changepassword
        )
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  */

  return (
    <div ref={topRef}>
      { status.value > 0 ?
        <Alert
          title="Bevestiging"
          text={status.message}
          type={ status.value == 1 ? 'success' : 'warning'}
        />
      : null }

      <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_signup_form' onSubmit={submit} >

        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Registreren</h1>
          {/* end::Title */}

          <div className='text-gray-500 fw-semibold fs-6'>Maak een PostDropper account aan</div>
        </div>
        {/* end::Heading */}


        <div className='row mb-7'>
          <InputText
            label = { validations?.firstname.label }
            name = "firstname"
              values={[values, setValues]}
              validation={ validations?.firstname.value }
          /> 
        </div>
        <div className='row mb-7'>
          <InputText
            label = { validations?.lastname.label }
            name = "lastname"
            values={[values, setValues]}
            validation={ validations?.lastname.value }
          />
        </div>
        <div className='row mb-7'>
          <InputText
            label = { validations?.email.label }
            name = "email"
            values={[values, setValues]}
            validation={ validations?.email.value }
            placeholder='jan@jansen.nl'
          />
        </div>
        <div className='row mb-7'>
          <InputText
            label = { validations?.phone.label }
            name = "phone"
            values={[values, setValues]}
            validation={ validations?.phone.value }
            placeholder="+31612345678"
          />
        </div>
        <div className='row mb-7'>
          <InputPassword
            label = { validations?.password.label }
            name = "password"
            values={[values, setValues]}
            validation={ validations?.password.value }
            placeholder="minimaal 8 karakters"
          />
        </div>
        <div className='row mb-7'>
          <InputSwitch
            label = "Ik ga accoord met de "
            link = 'https://fonts.google.com/'
            linkTitle="voorwaarden"            
            name = "acceptTerms"
            values={[values, setValues]}
            validation={ validations?.acceptTerms.value }
          />
        </div>


        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-postdroppers-modal-action='cancel'
          >
            Annuleren
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-postdroppers-modal-action='submit'
            disabled={false}
          >
            <span className='indicator-label'>Opslaan</span>
          </button>
        </div>
        {/* end::Actions */}
      </form>
    </div>
  )
}
